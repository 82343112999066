import * as React from "react"
import { useSpinDelay } from "spin-delay"
import { cn } from "#app/utils/misc.tsx"
import { Icon } from "./icon.tsx"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip.tsx"

interface StatusButtonProps extends React.ComponentPropsWithoutRef<"button"> {
	variant?: "primary" | "secondary" | "destructive"
	status: "pending" | "success" | "error" | "idle"
	message?: string | null
	spinDelay?: Parameters<typeof useSpinDelay>[1]
}

export const StatusButton = React.forwardRef<HTMLButtonElement, StatusButtonProps>(
	({ message, status, className, children, variant = "primary", spinDelay, ...props }, ref) => {
		const delayedPending = useSpinDelay(status === "pending", {
			delay: 400,
			minDuration: 300,
			...spinDelay,
		})
		const companion = {
			pending: delayedPending ? (
				<div className="inline-flex h-6 w-6 items-center justify-center">
					<Icon name="update" className="animate-spin" />
				</div>
			) : null,
			success: (
				<div className="inline-flex h-6 w-6 items-center justify-center">
					<Icon name="check" />
				</div>
			),
			error: (
				<div className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-destructive">
					<Icon name="cross-1" className="text-destructive-foreground" />
				</div>
			),
			idle: null,
		}[status]

		return (
			<button
				ref={ref}
				className={cn(`btn btn-${variant} flex justify-center gap-4`, className)}
				{...props}
			>
				<div>{children}</div>
				{message ? (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>{companion}</TooltipTrigger>
							<TooltipContent>{message}</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				) : (
					companion
				)}
			</button>
		)
	},
)

StatusButton.displayName = "Button"
